import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiStarLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { LoyaltyInfoCard } from '~/components/Loyalty/components';

export interface LoyaltyCouponProps {
  discountValue: number;
}

const LoyaltyCoupon = ({ discountValue }: LoyaltyCouponProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const renderCard = useMemo(
    () => (
      <LoyaltyInfoCard
        isDarkText
        icon={<RiStarLine size={20} color={theme.colors.backgroundColor} />}
        text={
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslation('menu.youEarnedDiscount', { discountValue: convertToCurrency(discountValue) })
            }}
          />
        }
      />
    ),
    [discountValue, getTranslation, theme.colors.backgroundColor]
  );

  return renderCard;
};

export default LoyaltyCoupon;
