import styled, { css } from 'styled-components';
import { motion, MotionProps } from 'framer-motion';

const containerModifiers = {
  withNoElements: () => css`
    padding-bottom: calc(50px + 64px);
  `,
  withOneElement: () => css`
    padding-bottom: calc(100px + 64px);
  `,
  withBothElements: () => css`
    padding-bottom: calc(136px + 64px);
  `
};

type ContainerProps = MotionProps & { hasCartItems: boolean; hasLoyaltyCard: boolean };

const containerAttrs = (props: ContainerProps) => ({
  ...props,
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  animate: { opacity: 1 }
});

export const Container = styled(motion.div).attrs(containerAttrs)`
  ${({ theme, hasCartItems, hasLoyaltyCard }) => css`
    position: relative;

    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: ${theme.colors.gray200};

    > h1 {
      color: ${theme.colors.backgroundColor};
      font-size: 48px;
    }

    ${!hasCartItems && containerModifiers.withNoElements()};
    ${hasCartItems && !hasLoyaltyCard && containerModifiers.withOneElement()};
    ${hasCartItems && hasLoyaltyCard && containerModifiers.withBothElements()};
  `}
`;

export const GoomerExperienceWrap = styled.div<{ $hasCartItems: boolean }>`
  ${({ $hasCartItems }) => css`
    position: absolute;
    bottom: 4.5rem;
    left: 50%;
    transform: translateX(-50%);

    margin: 0 auto !important;

    ${$hasCartItems &&
    css`
      bottom: 8.5rem !important;
    `}
  `}
`;

export const ThirkyWrapper = styled.div<{ $hasPadding: boolean }>`
  ${({ $hasPadding }) => css`
    width: 100%;
    margin: 0 auto;
    max-width: 800px;

    ${$hasPadding &&
    css`
      padding: 1rem;
      padding-bottom: 0.5rem;
    `}
  `}
`;

export const EmptyCartWrapper = styled.div`
  height: 60vh;
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .empty-products-animation {
    width: 21.87rem;
    height: 21.87rem;
  }
`;

export const InfoTitle = styled.span`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.68rem;

  color: ${({ theme }) => theme.colors.textColor};
`;

export const InfoSubtitle = styled.span`
  margin-top: 0.5rem;

  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.22rem;

  color: ${({ theme }) => theme.colors.gray500};
`;
