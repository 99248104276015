import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin-top: 1rem;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    margin-bottom: 0.5rem;

    font-weight: 700;
    line-height: 140%;
    font-size: 0.75rem;

    color: ${theme.colors.gray500};
  `};
`;

export const BenefitList = styled.div<{ cardsCount: number; isMobile: boolean }>`
  ${({ isMobile, cardsCount }) => css`
    display: flex;
    align-items: center;
    flex-wrap: nowrap !important;

    overflow: hidden;

    ${isMobile &&
    cardsCount > 1 &&
    css`
      overflow-x: auto;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    `}

    ${!isMobile &&
    css`
      padding-bottom: ${cardsCount > 1 && '4px'};

      overflow-x: ${cardsCount > 2 ? 'scroll' : 'hidden'};
    `}


    > div {
      max-height: 3.38rem;
      margin-right: 0.5rem;

      > div {
        max-height: 3.38rem;
      }

      ${isMobile
        ? css`
            min-width: ${cardsCount > 1 ? '80%' : '100%'};
          `
        : css`
            width: fit-content;
            min-width: ${cardsCount > 1 ? '300px' : '100%'};
          `}
    }
  `};
`;

export const BenefitModalList = styled.div`
  ${({ theme }) => css`
    padding: 1.5rem 1rem;

    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.backgroundColor};
  `};
`;
