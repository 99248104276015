import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import { RiArrowRightSLine } from 'react-icons/ri';

import { useEstablishmentStatus } from '~/hooks';
import { StoreStatus, StoreCategory } from '~/components';

import * as S from './styles';

export interface StoreInfoProps {
  logoUrl?: string;
  category?: string;
  storeName?: string;
}

const StoreInfo = ({ logoUrl, category, storeName }: StoreInfoProps): JSX.Element => {
  const history = useRouter();
  const { status, prettyStatus } = useEstablishmentStatus({});

  return (
    <S.Container
      isMobile={isMobile}
      onClick={(): void => {
        history.push('/sobre');
      }}
    >
      {!!logoUrl && <S.LogoContainer logoUrl={logoUrl} title="logo-container" />}

      <S.Info>
        <S.NameWrapper>
          {!!storeName && <S.StoreName>{storeName}</S.StoreName>}

          <RiArrowRightSLine size={24} />
        </S.NameWrapper>

        <S.MoreInfos>
          {!!category && <StoreCategory category={category} />}

          <StoreStatus status={status} text={prettyStatus} />
        </S.MoreInfos>
      </S.Info>
    </S.Container>
  );
};

export default StoreInfo;
