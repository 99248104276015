import styled, { css } from 'styled-components';

import { Button, Modal } from '~/components';
import { ModalProps } from '~/components/Modal/Modal';
import { ButtonProps } from '~/components/Button/Button';

export const Container = Modal
  ? styled(Modal)`
      .container-modal {
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => (theme.isDarkMode ? theme.colors.gray400 : theme.colors.backgroundColor)};

        max-width: 800px;
      }
    `
  : styled.div<ModalProps>``;

export const Title = styled.h3`
  ${({ theme }) => css`
    margin-top: 16px;

    font-size: 20px;
    font-weight: bold;
    line-height: 140%;
    text-align: center;
    color: ${theme.colors.textColor};
  `}
`;

export const Description = styled.h4`
  ${({ theme }) => css`
    margin-top: 8px;

    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    color: ${theme.colors.mediumGray};
  `}
`;

export const Tip = styled.strong`
  ${({ theme }) => css`
    margin-top: 24px;

    display: block;

    font-size: 14px;
    font-weight: bold;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
    color: ${theme.colors.mediumGray};
  `}
`;

interface CustomButtonProps extends ButtonProps {
  hasTip: boolean;
}

export const CustomButton = styled(Button)<CustomButtonProps>`
  margin-top: ${({ hasTip }) => (hasTip ? '24px' : '69px')};
`;
