import { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { RiEBike2Line, RiShoppingBasket2Line, RiBarcodeBoxLine } from 'react-icons/ri';

import TableIcon from '~/assets/TableIcon';
import GoomerLogo from '~/assets/GoomerLogo';
import { IDeliveryInfo } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { convertToCurrency, getDeliveryTime } from '~/utils';
import { useAddress, useTabInfo, useTranslator } from '~/hooks';
import { TextWithIcon, NewWaves, AddressModal } from '~/components';
import { DeliveryPricingEnum, LocalOrdersEnum, OrderModeEnum } from '~/interfaces/enums';

import { Benefits, StoreInfo } from '..';

import * as S from './styles';

interface MenuTopInfosProps {
  delivery?: IDeliveryInfo;
}

function MenuTopInfos({ delivery }: MenuTopInfosProps): JSX.Element {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const localOrders = useSelector((state: IApplicationState) => state.localOrders);
  const { address, deliveryFee } = useSelector((state: IApplicationState) => state.cart);
  const { settings, isViewMode } = useSelector((state: IApplicationState) => state.establishment);

  const [isAddressModalVisible, setIsAddressModalVisible] = useState<boolean>(false);

  const tabInfo = useTabInfo();
  const { isStatic } = useAddress();
  const { getTranslation } = useTranslator();

  const isAbrahaoStore = useMemo(() => settings?.is_abrahao, [settings?.is_abrahao]);

  const isLocalOrder = useMemo(() => localOrders.mode.length > 0, [localOrders.mode.length]);

  const isLocalOrderTable = useMemo(() => localOrders.mode === LocalOrdersEnum.table, [localOrders.mode]);

  const shouldDisplayQrCodeHeaderLayout: boolean = useMemo(
    () => !isViewMode && isLocalOrder,
    [isLocalOrder, isViewMode]
  );

  const shouldDisplayTableAndCardToControlOrders: boolean = useMemo(
    () => !!isAbrahaoStore && settings?.mm_order_mode == OrderModeEnum.Tab && !!tabInfo?.text,
    [isAbrahaoStore, settings?.mm_order_mode, tabInfo?.text]
  );

  const modeTagInfo: {
    icon: JSX.Element;
    text: string;
  } = useMemo(
    () =>
      shouldDisplayTableAndCardToControlOrders
        ? {
            icon: <TableIcon />,
            text: `${getTranslation(localOrders.mode)} ${localOrders.number} • ${getTranslation('general.tab')} ${
              tabInfo?.number
            }`
          }
        : {
            text: `${getTranslation(localOrders.mode)} ${localOrders.number}`,
            icon: isLocalOrderTable ? <TableIcon /> : <RiBarcodeBoxLine size={20} />
          },
    [
      getTranslation,
      tabInfo?.number,
      localOrders.mode,
      isLocalOrderTable,
      localOrders.number,
      shouldDisplayTableAndCardToControlOrders
    ]
  );

  const renderHeaderContent: JSX.Element | null = useMemo(() => {
    if (shouldDisplayQrCodeHeaderLayout) {
      return (
        <S.QrCodeModeTag $hasBanner={!!settings?.mm_banner_url}>
          {modeTagInfo.icon}

          <S.QrCodeModeTagDescription>{modeTagInfo.text}</S.QrCodeModeTagDescription>
        </S.QrCodeModeTag>
      );
    }

    if (!settings?.mm_banner_url) {
      return (
        <S.FilterWrapper>
          <GoomerLogo className="goomer-logo" />
        </S.FilterWrapper>
      );
    }

    return null;
  }, [modeTagInfo.icon, modeTagInfo.text, settings?.mm_banner_url, shouldDisplayQrCodeHeaderLayout]);

  const renderDefaultHeader = (): JSX.Element => (
    <S.DefaultHeader bannerUrl={settings?.mm_banner_url}>{renderHeaderContent}</S.DefaultHeader>
  );

  const getTime = useCallback(() => {
    if (!deliveryFee) {
      return '';
    }

    return getDeliveryTime({ deliveryFee });
  }, [deliveryFee]);

  const getPrice = useCallback(() => {
    let pricing;

    switch (deliveryFee?.pricing) {
      case DeliveryPricingEnum.free:
        pricing = getTranslation('general.free');
        break;
      case DeliveryPricingEnum.ask:
        pricing = getTranslation('address.feeToBeAgreed');
        break;
      case DeliveryPricingEnum.outside:
        pricing = false;
        break;
      case DeliveryPricingEnum.notFound:
        pricing = DeliveryPricingEnum.notFound;
        break;
      default:
        pricing = deliveryFee?.pricing ? `${convertToCurrency(Number(deliveryFee?.pricing))}` : '';
        break;
    }

    return pricing;
  }, [deliveryFee?.pricing, getTranslation]);

  const deliveryTime: string = getTime();
  const deliveryPrice: boolean | string = getPrice();

  const getDeliveryText: () => string = useCallback(() => {
    if (isStatic && !address) {
      return getTranslation('address.selectAddressToDelivery');
    }

    if (!address) {
      return getTranslation('address.deliveryTimeAndTax');
    }

    if (deliveryPrice === DeliveryPricingEnum.notFound) {
      return getTranslation('address.addressNotFoundContactStore');
    }

    if (!deliveryPrice) {
      return getTranslation('address.tooFarDescription');
    }

    return `${getTranslation('address.approximateDelivery')} ${deliveryTime} ${
      !!deliveryPrice && typeof deliveryPrice === 'string' ? `(${deliveryPrice})` : ''
    }`;
  }, [isStatic, address, deliveryPrice, deliveryTime, getTranslation]);

  return (
    <>
      <S.Container>
        {renderDefaultHeader()}

        <S.Content isLongHeaderMode={!!settings?.mm_banner_url || shouldDisplayQrCodeHeaderLayout}>
          <StoreInfo storeName={settings?.name} logoUrl={settings?.mm_logo_url} category={settings?.specialty_name} />

          {((!isViewMode && !!delivery && !isLocalOrder) ||
            (!!settings?.mm_delivery_minimum_value_enabled && !isLocalOrder)) && (
            <div className="info-wrapper">
              {!isViewMode && !!delivery && !isLocalOrder && (
                <TextWithIcon
                  icon={<RiEBike2Line size={20} />}
                  text={getDeliveryText()}
                  onClick={(): void => setIsAddressModalVisible(true)}
                />
              )}

              {!!settings?.mm_delivery_minimum_value_enabled && !isLocalOrder && (
                <TextWithIcon
                  icon={<RiShoppingBasket2Line size={20} />}
                  text={`${getTranslation('payment.minimumOrder')}: ${convertToCurrency(
                    Number(settings?.mm_delivery_minimum_value)
                  )}`}
                />
              )}
            </div>
          )}

          {!isViewMode && !localOrders.isOperatorMode && <Benefits />}
        </S.Content>

        <NewWaves backgroundColor={theme.colors.gray200} />
      </S.Container>

      <AddressModal
        isModalVisible={isAddressModalVisible}
        setIsModalVisible={(isModalVisible): void => setIsAddressModalVisible(isModalVisible)}
      />
    </>
  );
}

export default MenuTopInfos;
