import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiCoupon3Line } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { DiscountType } from '~/interfaces/general';
import { DiscountTypeEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

export interface CouponProps {
  name: string;
  orderMinValue: number;
  discountValue: number;
  isSimpleCard?: boolean;
  discountType: DiscountType;
}

const Coupon: (props: CouponProps) => JSX.Element = ({
  name,
  isSimpleCard,
  discountType,
  orderMinValue,
  discountValue
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const formatValue = useCallback((value: number | string) => convertToCurrency(Number(value)).replace(/\s/g, ''), []);

  const handleDiscountTypeText = useMemo(() => {
    if (discountType === DiscountTypeEnum.percent) return `${discountValue}%`;

    return formatValue(discountValue);
  }, [discountType, discountValue, formatValue]);

  const hasMinimumValue: boolean = useMemo(() => Number(orderMinValue) > 0, [orderMinValue]);

  const couponContent = useMemo(
    () =>
      `${getTranslation('menu.youHasDiscount', { name, discountText: handleDiscountTypeText })} ${
        hasMinimumValue ? getTranslation('menu.hasMinimumValue', { minValue: formatValue(orderMinValue) }) : ''
      }`,
    [formatValue, getTranslation, handleDiscountTypeText, hasMinimumValue, name, orderMinValue]
  );

  const renderCard = useMemo(() => {
    return (
      <S.Content isSimpleCard={isSimpleCard} data-test="benefits-coupon-card">
        <S.SvgWrapper>
          <RiCoupon3Line size={16} color={theme.colors.backgroundColor} />
        </S.SvgWrapper>

        <div>
          <p dangerouslySetInnerHTML={{ __html: couponContent }} />
        </div>
      </S.Content>
    );
  }, [isSimpleCard, theme.colors.backgroundColor, couponContent]);

  if (isSimpleCard) return renderCard;

  return <S.Container>{renderCard}</S.Container>;
};

export default Coupon;
