import hexToRgba from 'hex-to-rgba';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    background: ${theme.colors.backgroundColor};
  `}
`;

export const DefaultHeader = styled.div<{ bannerUrl?: string }>`
  ${({ theme, bannerUrl }) => css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 7.5rem;

    display: flex;
    justify-content: center;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${bannerUrl});
    background-color: ${theme.brandColors.actionBackground} !important;

    svg {
      margin-top: 0.75rem;
    }
  `}
`;

export const FilterWrapper = styled.div<{ bannerUrl?: string }>`
  ${({ theme, bannerUrl }) => css`
    ${bannerUrl &&
    css`
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${hexToRgba(theme.colors.pureTextColor, 0.4)};
    `}

    svg {
      &.goomer-logo {
        path {
          fill: ${theme.brandColors.actionText} !important;
        }
      }
    }
  `}
`;

export const Content = styled.div<{ isLongHeaderMode: boolean }>`
  ${({ theme, isLongHeaderMode }) => css`
    position: relative;
    z-index: 21;

    padding: 1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    margin-top: ${isLongHeaderMode ? '6rem' : '2.25rem'};

    background-color: ${theme.colors.backgroundColor};

    .info-wrapper {
      margin-top: 0.75rem;

      gap: 0.5rem;
      display: flex;
      flex-direction: column;
    }
  `}
`;

export const QrCodeModeTag = styled.div<{ $hasBanner: boolean }>`
  ${({ theme, $hasBanner }) => css`
    top: 2rem;
    position: absolute;

    border-radius: 5rem;
    padding: 0.25rem 0.5rem;
    border: 1px solid ${!$hasBanner ? theme.brandColors.actionText : '#fff'};

    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${hexToRgba(!$hasBanner ? 'transparent' : '#000', 0.4)};

    > span {
      color: ${!$hasBanner ? theme.brandColors.actionText : '#fff'};
    }

    > svg {
      margin-top: 0;

      fill: ${!$hasBanner ? theme.brandColors.actionText : '#fff'};

      &.table-icon {
        fill: transparent;
      }

      > path {
        stroke: ${!$hasBanner ? theme.brandColors.actionText : '#fff'};
      }
    }
  `}
`;

export const QrCodeModeTagDescription = styled.span`
  max-width: 12.5rem;

  font-weight: 600;
  font-size: 0.875rem;
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
`;
