import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import lottie from 'lottie-web';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { Group } from '~/interfaces';
import { IApplicationState } from '~/redux-tools/store';
import { useMenuGroups, useMenuProducts } from '~/hooks/useMenu/hooks';
import { MenuSessionStorageEnum, QueryParam } from '~/interfaces/enums';
import { MenuTopInfos, NewFeatureModal } from '~/components/pages/Menu';
import { removeCheckoutData } from '~/redux-tools/store/checkout/actions';
import { LoyaltyValidOrderMenuCard } from '~/components/Loyalty/components';
import emptyProductsAnimation from '~/assets/animations/menuAnimations/emptyProducts.json';
import { isThisChromeVersionSupported, getFilteredListByAvailableHours, localStorage } from '~/utils';
import {
  useMenu,
  useThirky,
  useTranslator,
  useHasOrderStatus,
  useNavigationList,
  useLoyaltyProgram,
  useUserAuthenticationStatus
} from '~/hooks';
import {
  CartInfo,
  Carousel,
  MenuList,
  LastOrder,
  Navigator,
  ThirkyAds,
  HighlightCard,
  CategoryFilter,
  ProductCardList,
  GoomerExperience,
  SimpleFlashMessage,
  MobileCategoryFilter,
  Group as GroupComponent
} from '~/components';

import * as S from './styles';

export interface LastSelectedCategoryProps {
  id: string;
  categoryName: string;
}

const Menu = (): JSX.Element => {
  const dispatch = useDispatch();

  const { mode: localOrder } = useSelector((state: IApplicationState) => state.localOrders);
  const { settings, loading: loadingInfo } = useSelector((state: IApplicationState) => state.establishment);
  const {
    highlights,
    isMenuListEmpty,
    isLoading: isMenuLoading
  } = useSelector((state: IApplicationState) => state.menus);

  const [yOffset] = useState<number>(76);
  const [hasThirkyAds, setHasThirkyAds] = useState<boolean>(false);
  const [thirkyMediaHeight, setThirkyMediaHeight] = useState<number>(0);
  const [shouldDisplayLastOrderModal, setShouldDisplayLastOrderModal] = useState<boolean>(true);
  const [lastSelectedCategoryId, setLastSelectedCategoryId] = useState<LastSelectedCategoryProps | null>(null);

  useThirky();
  const { getTranslation } = useTranslator();
  const navigationList = useNavigationList();
  const { goNavigation, inStoreNavigation } = navigationList;
  const { inProgress, hasOrderStatus } = useHasOrderStatus();
  const { allProducts: allProductsList } = useMenuProducts();
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { isItemAvailableByHours } = getFilteredListByAvailableHours;
  const { allAvailableGroups, currentMenuAvailableGroups } = useMenuGroups();
  const { isLoyaltyTemporarilyBlocked, shouldDisplayValidOrderCard, shouldDisplayLoyaltyProgramMenuInfo } =
    useLoyaltyProgram();
  const {
    delivery,
    showBagFlash,
    hasCartItems,
    showLastOrder,
    showWelcomeFlash,
    showFeatureModal,
    setShowFeatureModal,
    isFeatureModalHasBeenSeen
  } = useMenu();

  const isMultipleMenusLayoutEnabled = useMemo(
    () => !!settings?.mm_multiple_menus_layout_enabled,
    [settings?.mm_multiple_menus_layout_enabled]
  );

  const scrollToItemBasedOnUrl = useCallback(
    (categoryToScrollId: number) => {
      const scrollId = allAvailableGroups?.find((group) => group.id === Number(categoryToScrollId))?.id;

      if (!scrollId) return;

      const element = typeof document !== 'undefined' && document.getElementById(scrollId.toString());

      if (!element) return;

      const yCoordinate = element?.offsetTop - yOffset;

      if (isThisChromeVersionSupported()) {
        scrollTo({ top: yCoordinate, behavior: 'smooth' });
      }
    },
    [allAvailableGroups, yOffset]
  );

  useEffect(() => {
    dispatch(removeCheckoutData());
  }, [dispatch]);

  useEffect(() => {
    const lastSelectedCategoryIdSessionData = sessionStorage.getItem(
      MenuSessionStorageEnum.lastSelectedCategoryIdSessionData
    );

    const getSessionStorageInfo = lastSelectedCategoryIdSessionData
      ? (JSON.parse(lastSelectedCategoryIdSessionData) as LastSelectedCategoryProps)
      : undefined;

    if (getSessionStorageInfo) {
      setLastSelectedCategoryId(getSessionStorageInfo);
    }
  }, []);

  useEffect(() => {
    if ((isMultipleMenusLayoutEnabled ? !currentMenuAvailableGroups : !allAvailableGroups) || !lastSelectedCategoryId)
      return;

    scrollToItemBasedOnUrl(Number(lastSelectedCategoryId.id));

    setLastSelectedCategoryId(null);
    sessionStorage.removeItem(MenuSessionStorageEnum.lastProductSelectedSessionData);
    sessionStorage.removeItem(MenuSessionStorageEnum.lastSelectedCategoryIdSessionData);
  }, [
    allAvailableGroups,
    scrollToItemBasedOnUrl,
    lastSelectedCategoryId,
    currentMenuAvailableGroups,
    isMultipleMenusLayoutEnabled
  ]);

  const isAvailableHoursEnabled = useMemo(
    () => !!settings?.mm_feature_schedule_by_group_and_product_enabled,
    [settings?.mm_feature_schedule_by_group_and_product_enabled]
  );

  const highlightItemList: JSX.Element[] = useMemo(() => {
    const availableHighlightList = highlights.filter(({ product, available_hours }) => {
      const isHighlightAvailable = isItemAvailableByHours(available_hours, isAvailableHoursEnabled);
      const isHighlightProductAvailable = isItemAvailableByHours(
        product?.available_hours || [],
        isAvailableHoursEnabled
      );

      return isHighlightAvailable && isHighlightProductAvailable;
    });

    return availableHighlightList.map((item, index) => {
      const elementId = `highlight-card-${index}`;

      return (
        <HighlightCard
          {...item}
          id={elementId}
          key={elementId}
          isSingle={highlights.length === 1}
          isAvailable={!!item.product?.is_available}
        />
      );
    });
  }, [highlights, isAvailableHoursEnabled, isItemAvailableByHours]);

  const emptyProductsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (emptyProductsRef.current && (isMenuListEmpty || allProductsList.length === 0)) {
      lottie.destroy();

      lottie.loadAnimation({
        loop: true,
        autoplay: true,
        renderer: 'svg',
        container: emptyProductsRef.current,
        animationData: emptyProductsAnimation
      });
    }

    return () => {
      lottie.destroy();
    };
  }, [allProductsList.length, isMenuListEmpty]);

  if (!settings && (loadingInfo || isMenuLoading)) {
    return <></>;
  }

  const renderLayout = ({ group, index }: { group: Group; index: number }): JSX.Element => {
    const commonProperties = { key: index, group: group };

    return isMultipleMenusLayoutEnabled ? (
      <ProductCardList {...commonProperties} />
    ) : (
      <GroupComponent {...commonProperties} />
    );
  };

  return (
    <>
      <ThirkyAds mediaType="preRoll" elementId="menu-thirky-pre-roll" />

      <S.Container id="product-scroll" hasCartItems={hasCartItems} hasLoyaltyCard={shouldDisplayLoyaltyProgramMenuInfo}>
        <MenuTopInfos delivery={delivery} />

        <S.ThirkyWrapper $hasPadding={hasThirkyAds}>
          <ThirkyAds
            mediaType="highlight"
            elementId="menu-thirky-highlight"
            mediaHeight={setThirkyMediaHeight}
            hasMediaToDisplay={setHasThirkyAds}
          />
        </S.ThirkyWrapper>

        {allAvailableGroups && allAvailableGroups.length > 0 && !isMultipleMenusLayoutEnabled && (
          <>
            {isMobile ? (
              <MobileCategoryFilter groupList={allAvailableGroups} />
            ) : (
              <div>
                <CategoryFilter
                  hasThirkyAds={hasThirkyAds}
                  thirkyMediaHeight={thirkyMediaHeight}
                  groupList={allAvailableGroups}
                />
              </div>
            )}
          </>
        )}

        {isMultipleMenusLayoutEnabled && <MenuList />}

        {highlightItemList.length > 0 && (
          <div style={{ marginBottom: '2rem' }}>
            <Carousel itemList={highlightItemList} />
          </div>
        )}

        <div id="group-list">
          {(isMultipleMenusLayoutEnabled ? currentMenuAvailableGroups : allAvailableGroups)?.map((group, index) =>
            renderLayout({ group, index })
          )}
        </div>

        {showBagFlash && <SimpleFlashMessage type={QueryParam.bag} positionFromBottom={'50px'} />}

        {showWelcomeFlash && isUserAuthenticated && (
          <SimpleFlashMessage
            hideTimeout={3000}
            type={QueryParam.welcome}
            isReturningUser={showWelcomeFlash === 'return'}
          />
        )}

        {hasCartItems && shouldDisplayValidOrderCard && !isLoyaltyTemporarilyBlocked && <LoyaltyValidOrderMenuCard />}

        <CartInfo sendTo={localOrder ? '/localOrder' : '/order'} />

        <Navigator
          active="menu"
          items={localOrder ? inStoreNavigation : goNavigation}
          showCount={!!localOrder && hasOrderStatus && inProgress}
        />

        {!settings?.mm_order_status_check_enabled && showLastOrder && (
          <LastOrder
            isVisible={shouldDisplayLastOrderModal}
            onChange={(value: boolean): void => setShouldDisplayLastOrderModal(value)}
          />
        )}

        {!localOrder && !isFeatureModalHasBeenSeen('order-scheduling') && !!settings?.mm_order_scheduling_only && (
          <NewFeatureModal
            isShow={showFeatureModal}
            btnText={getTranslation('general.okayLetsGo')}
            name={getTranslation('scheduling.scheduleOrder')}
            description={getTranslation('scheduling.nowYouCanScheduleYourOrder')}
            onClose={(): void => {
              setShowFeatureModal(false);
              setTimeout(() => localStorage?.setItem('feature-banner', 'order-scheduling'), 1000);
            }}
          />
        )}

        {(isMenuListEmpty || allProductsList.length === 0) && (
          <S.EmptyCartWrapper>
            <div ref={emptyProductsRef} className="empty-products-animation" />
            <S.InfoTitle>{getTranslation('general.noProductsRegistered')}</S.InfoTitle>
            <S.InfoSubtitle>{getTranslation('general.newsSoon')}</S.InfoSubtitle>
          </S.EmptyCartWrapper>
        )}

        <S.GoomerExperienceWrap $hasCartItems={hasCartItems}>
          <GoomerExperience />
        </S.GoomerExperienceWrap>
      </S.Container>
    </>
  );
};

export default Menu;
