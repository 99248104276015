import styled, { css } from 'styled-components';

const logoSize = '4rem';

export const Container = styled.div<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => css`
    display: flex;
    align-items: center;

    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.857rem;
      border: 1px solid ${theme.colors.gray200};
    }

    ${isMobile &&
    css`
      .logo-container {
        min-width: 20vw;
        min-height: 20vw;
      }
    `}
  `}
`;

export const LogoContainer = styled.div<{ logoUrl?: string }>`
  ${({ theme, logoUrl }) => css`
    margin-right: 1rem;
    min-width: ${logoSize};
    max-width: ${logoSize};
    min-height: ${logoSize};
    max-height: ${logoSize};
    border-radius: 0.75rem;
    border: 1px solid ${theme.colors.gray300};

    background-size: cover;
    background-position: center;
    background-image: url(${logoUrl});
    background-color: ${theme.brandColors.primary};
  `}
`;

export const Info = styled.div`
  width: 100%;
  min-height: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StoreName = styled.strong`
  ${({ theme }) => css`
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${theme.colors.textColor};
  `}
`;

export const MoreInfos = styled.div`
  gap: 0.5rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const NameWrapper = styled.div`
  width: 100%;
  gap: 0.25rem;

  display: flex;
  justify-content: space-between;
`;
