import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiShoppingBagLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { ICoupon } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

interface FirstOrderCouponProps {
  coupon: ICoupon;
  errorMessage?: string;
}

const FirstOrderCoupon = ({ coupon, errorMessage }: FirstOrderCouponProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const renderCoupon = useMemo(() => {
    return (
      <S.Content>
        <S.SvgWrapper>
          <RiShoppingBagLine color={theme.colors.backgroundColor} size={16} />
        </S.SvgWrapper>

        <S.SectionWrapper>
          <S.SectionDescription>
            {errorMessage ? (
              <p dangerouslySetInnerHTML={{ __html: getTranslation('menu.firstOrderInStore', { errorMessage }) }} />
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslation('menu.youHasDiscountInFirstOrder', {
                    couponValue:
                      coupon.type === 'absolute'
                        ? convertToCurrency(coupon.value)
                        : `${coupon.value}% ${getTranslation('menu.ofDiscount')}`
                  })
                }}
              />
            )}
          </S.SectionDescription>
        </S.SectionWrapper>
      </S.Content>
    );
  }, [coupon.type, coupon.value, errorMessage, getTranslation, theme.colors.backgroundColor]);

  return renderCoupon;
};

export default FirstOrderCoupon;
