import React, { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { RiArrowRightSLine } from 'react-icons/ri';

import { Coupon } from '~/components/pages/Menu';
import { IApplicationState } from '~/redux-tools/store';
import { ICoupon, LocalOrder } from '~/interfaces/general';
import { LoyaltyWarning } from '~/components/Loyalty/components';
import { FeaturesEnum, SegmentTypeEnum } from '~/interfaces/enums';
import { Loyalty, FirstOrderCard, FreeDelivery, Modal } from '~/components';
import { useHasFeatureAccess, useLoyaltyProgram, useTranslator, useUserAuthenticationStatus } from '~/hooks';

import { FirstOrderCoupon, LoyaltyCoupon } from '../';

import * as S from './styles';

const Benefits: () => JSX.Element = () => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const localOrders: LocalOrder = useSelector((state: IApplicationState) => state.localOrders);
  const loyaltyProgramDiscount = useSelector((state: IApplicationState) => state.cart.values.loyalty);
  const { data: couponData, error: couponError } = useSelector((state: IApplicationState) => state.coupons);
  const { isFirstOrder, customerLoyaltyProgram } = useSelector((state: IApplicationState) => state.user.data);
  const {
    settings,
    isViewMode,
    loyaltyProgram,
    coupons: establishmentCouponList
  } = useSelector((state: IApplicationState) => state.establishment);

  const [shouldDisplayBenefitsModal, setShouldDisplayBenefitsModal] = useState<boolean>(false);

  const { selectedCoupon } = couponData;

  const { getTranslation } = useTranslator();
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { isLoyaltyTemporarilyBlocked, shouldDisplayLoyaltyProgramMenuInfo } = useLoyaltyProgram();
  const [hasFirstOrderDiscountAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.FirstOrderDiscount });

  const isLocalOrder: boolean = useMemo(() => localOrders.mode.length > 0, [localOrders]);

  const firstOrderCoupon: ICoupon | undefined = useMemo(
    () => establishmentCouponList?.find((coupon) => coupon.segment === SegmentTypeEnum.first_order),
    [establishmentCouponList]
  );

  const hasFirstOrderCardAccess: boolean = useMemo(
    () => !isUserAuthenticated && (firstOrderCoupon?.enabled || false),
    [firstOrderCoupon, isUserAuthenticated]
  );

  const shouldDisplayFirstOrderCardComponent: boolean = useMemo(
    () =>
      !!hasFirstOrderDiscountAccess && !isViewMode && !isLocalOrder && !!hasFirstOrderCardAccess && !!firstOrderCoupon,
    [firstOrderCoupon, hasFirstOrderDiscountAccess, isLocalOrder, isViewMode, hasFirstOrderCardAccess]
  );

  const shouldDisplayFirstOrderCoupon: boolean = useMemo(
    () => selectedCoupon?.segment === SegmentTypeEnum.first_order && !!isFirstOrder,
    [isFirstOrder, selectedCoupon?.segment]
  );

  const shouldDisplayFreeDeliveryCard: boolean = useMemo(
    () => !!settings && settings?.mm_free_delivery_enabled && !isLocalOrder,
    [isLocalOrder, settings]
  );

  const shouldDisplayLoyaltyCoupon: boolean = useMemo(
    () => !!loyaltyProgramDiscount && loyaltyProgramDiscount > 0,
    [loyaltyProgramDiscount]
  );

  const shouldDisplayLoyaltyWarning: boolean = useMemo(
    () =>
      shouldDisplayLoyaltyProgramMenuInfo && !!isLoyaltyTemporarilyBlocked && !!customerLoyaltyProgram?.last_order_at,
    [customerLoyaltyProgram?.last_order_at, isLoyaltyTemporarilyBlocked, shouldDisplayLoyaltyProgramMenuInfo]
  );

  const shouldDisplayCoupon: boolean = useMemo(
    () => !!selectedCoupon && selectedCoupon.segment !== SegmentTypeEnum.first_order,
    [selectedCoupon]
  );

  const cardsCount: number = useMemo(() => {
    const cardsDisplayList: boolean[] = [
      shouldDisplayFirstOrderCardComponent,
      shouldDisplayFirstOrderCoupon,
      shouldDisplayLoyaltyProgramMenuInfo,
      shouldDisplayLoyaltyWarning,
      shouldDisplayLoyaltyCoupon,
      shouldDisplayFreeDeliveryCard,
      shouldDisplayCoupon
    ];

    return cardsDisplayList.filter((cardDisplay) => cardDisplay).length;
  }, [
    shouldDisplayCoupon,
    shouldDisplayLoyaltyCoupon,
    shouldDisplayLoyaltyWarning,
    shouldDisplayFreeDeliveryCard,
    shouldDisplayFirstOrderCoupon,
    shouldDisplayLoyaltyProgramMenuInfo,
    shouldDisplayFirstOrderCardComponent
  ]);

  const renderCardList = useMemo(() => {
    return (
      <>
        {/* Cupom de desconto */}
        {shouldDisplayCoupon && !!selectedCoupon && (
          <div>
            <Coupon
              isSimpleCard
              name={selectedCoupon.code || ''}
              discountType={selectedCoupon.type}
              discountValue={selectedCoupon.value}
              orderMinValue={selectedCoupon.order_min_value}
            />
          </div>
        )}

        {/* Desconto de Primeiro Pedido */}
        {shouldDisplayFirstOrderCardComponent && !!firstOrderCoupon && (
          <div>
            <FirstOrderCard coupon={firstOrderCoupon} isSimpleCard />
          </div>
        )}

        {shouldDisplayFirstOrderCoupon && !!selectedCoupon && (
          <div>
            <FirstOrderCoupon coupon={selectedCoupon} errorMessage={couponError} />
          </div>
        )}

        {/* Programa de Fidelidade */}
        {shouldDisplayLoyaltyProgramMenuInfo && !!loyaltyProgram && !shouldDisplayLoyaltyCoupon && (
          <>
            <div>
              <Loyalty
                discountValue={loyaltyProgram.rewardDiscount}
                programOrders={loyaltyProgram.ordersForFulfilling}
                userOrders={customerLoyaltyProgram?.total_valid_orders || 0}
                isLoyaltyProgramCompleted={!!loyaltyProgramDiscount && loyaltyProgramDiscount > 0}
              />
            </div>
          </>
        )}

        {shouldDisplayLoyaltyWarning && (
          <div>
            <LoyaltyWarning isSimpleCard />
          </div>
        )}

        {shouldDisplayLoyaltyCoupon && !!loyaltyProgramDiscount && (
          <div>
            <LoyaltyCoupon discountValue={loyaltyProgramDiscount} />
          </div>
        )}

        {/* Entrega grátis */}
        {shouldDisplayFreeDeliveryCard && !!settings && (
          <div>
            <FreeDelivery value={settings.mm_free_delivery_minimum_value} />
          </div>
        )}
      </>
    );
  }, [
    settings,
    couponError,
    selectedCoupon,
    loyaltyProgram,
    firstOrderCoupon,
    shouldDisplayCoupon,
    customerLoyaltyProgram,
    loyaltyProgramDiscount,
    shouldDisplayLoyaltyCoupon,
    shouldDisplayLoyaltyWarning,
    shouldDisplayFirstOrderCoupon,
    shouldDisplayFreeDeliveryCard,
    shouldDisplayLoyaltyProgramMenuInfo,
    shouldDisplayFirstOrderCardComponent
  ]);

  return (
    <S.Container>
      {cardsCount > 0 && (
        <S.Content>
          <S.Title onClick={(): void => setShouldDisplayBenefitsModal(true)}>
            {getTranslation('benefits.yourBenefits')}

            <RiArrowRightSLine size={12} />
          </S.Title>

          <S.BenefitList isMobile={isMobile} cardsCount={cardsCount}>
            {renderCardList}
          </S.BenefitList>
        </S.Content>
      )}

      <div className="benefits-modal">
        <Modal
          isPageLike
          isGhostFooter
          titleBackgroundColor={theme.colors.backgroundColor}
          headerTitle={getTranslation('benefits.yourBenefits')}
          isShow={shouldDisplayBenefitsModal && cardsCount > 0}
          footerTitle={getTranslation('navigation.returnToMenu')}
          onClose={(): void => setShouldDisplayBenefitsModal(false)}
          onFooterClick={(): void => setShouldDisplayBenefitsModal(false)}
        >
          <S.BenefitModalList>{renderCardList}</S.BenefitModalList>
        </Modal>
      </div>
    </S.Container>
  );
};

export default Benefits;
