import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 0.63rem;
    border-radius: 5px;
    border: 1px dashed ${theme.colors.success};

    display: flex;
    align-items: center;
  `};
`;

export const SvgWrapper = styled.div`
  ${({ theme }) => css`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    margin-right: 0.715rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.success};
  `};
`;

export const SectionWrapper = styled.div`
  flex: 1;
`;

export const SectionDescription = styled.div`
  > p {
    text-align: left;
    font-weight: 500;
    line-height: 140%;
    font-size: 0.63rem;
    color: ${({ theme }) => theme.colors.darkGray};

    strong {
      font-size: 0.63rem;
    }
  }
`;
