import React from 'react';

import { useTranslator } from '~/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import * as S from './styles';

export interface NewFeatureModalProps {
  tip?: string;
  name: string;
  isShow: boolean;
  btnText?: string;
  onClose: () => void;
  description: string;
}

const NewFeatureModal = ({ tip, name, isShow, onClose, btnText, description }: NewFeatureModalProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container isShow={isShow} onClose={onClose} isDrawer={false}>
      <LazyLoadImage src="/assets/scheduling/calendar.svg" width={128} height={128} />

      <S.Title>{name}</S.Title>

      <S.Description>{description}</S.Description>

      <S.Tip>{tip}</S.Tip>

      <S.CustomButton onClick={onClose} isGhost={false} hasTip={!!tip}>
        {btnText ?? getTranslation('general.okayGotIt')}
      </S.CustomButton>
    </S.Container>
  );
};

export default NewFeatureModal;
