import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 3;

    width: 100%;
    height: 70px;
    margin: 0 auto;
    max-width: 800px;
    box-shadow: 0px 2px 4px ${theme.colors.lightestGray};

    background-color: ${theme.colors.lightestGray};

    &:before,
    &:after {
      border-bottom: 40px solid ${theme.colors.lightestGray};

      @media screen and (max-width: 430px) {
        display: none;
      }
    }

    &:before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      height: 10px;

      background-image: radial-gradient(circle at 10px -15px, transparent 20px, ${theme.colors.lightestGray} 21px);
      content: '';
      background-size: 20px 40px;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      height: 15px;

      background-image: radial-gradient(circle at 10px 26px, ${theme.colors.lightestGray} 20px, transparent 21px);
      content: '';
      background-size: 40px 40px;
    }
  `}
`;

export const Content = styled.div<{ isSimpleCard?: boolean }>`
  ${({ theme, isSimpleCard }) => css`
    z-index: 10;

    ${!isSimpleCard &&
    css`
      top: 55%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);

      width: calc(100% - 20px);
    `}

    padding: 0.63rem;
    border-radius: 5px;
    border: 1px dashed ${theme.colors.tertiary};

    display: flex;
    align-items: center;

    line-height: 140%;
    font-size: 0.63rem;

    p {
      margin: 0;

      text-align: left;
      font-size: 0.63rem;
    }

    strong {
      font-size: 0.63rem;
      text-align: center;
    }
  `}
`;

export const SvgWrapper = styled.div`
  ${({ theme }) => css`
    height: 1.5rem;
    min-width: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.tertiary};
  `}
`;
